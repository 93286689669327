import { FAQstoreProvider } from './../../../../../../shared/providers/media/faq.provider';
import { Component, OnInit } from '@angular/core';
import { FAQ } from 'projects/shared/models/interfaces/media.model';
import { MediaService } from 'projects/shared/services/media.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent implements OnInit {
  faqList: FAQ[] = [];
  constructor(private faqStore: FAQstoreProvider ,private faqServ: MediaService) { }

  ngOnInit(): void {
    this.getFAQs();
  }
  getFAQs() {
    this.faqServ.getFAQs().subscribe((response) => {
      this.faqList = response.data;
    })
  }
}
