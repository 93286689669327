<section class="footer">
  <div class="header-footer">
    <div class="container">
      <div
        class="row justify-content-between text-center text-md-left align-items-center"
      >
        <div class="col-md-5 mb-md-0 mb-4">
          <h4 class="title-footer">Payment</h4>
          <div
            class="payment d-flex justify-content-between align-items-center"
          >
            <div class="item">
              <img
                src="../../../../assets/images/footer/payment/visa.svg"
                alt="visa logo"
                class="img-fluid"
              />
            </div>
            <div class="item">
              <img
                src="../../../../assets/images/footer/payment/master.svg"
                alt="master logo"
                class="img-fluid"
              />
            </div>
            <div class="item">
              <img
                src="../../../../assets/images/footer/payment/527px-PayPal_logo 1.svg"
                alt="PayPal logo"
                class="img-fluid"
              />
            </div>
            <div class="item">
              <img
                src="../../../../assets/images/footer/payment/web_money.svg"
                alt="web money logo"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <h4 class="title-footer">Social Media</h4>
          <div class="social d-flex align-items-center justify-content-center">
            <div class="item">
              <a href="https://www.facebook.com/Card4GamesLTD">
                <i class="pi pi-facebook"></i>
              </a>
            </div>
            <!-- <div class="item">
              <a href="http://www.facebook.com/">
                <i class="pi pi-twitter"></i>
              </a>
            </div> -->
            <div class="item">
              <a href="https://wa.link/sxc3ao">
                <i class="pi pi-whatsapp"></i>
              </a>
            </div>
            <!-- <div class="item">
              <a href="http://www.facebook.com/">
                <i class="pi pi-youtube"></i>
              </a>
            </div> -->
            <div class="item">
              <a href="https://www.instagram.com/card4games/">
                <i class="pi pi-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-footer">
    <div class="container">
      <div class="row justify-content-around">
        <div class="col-md-4">
          <h4 class="title-footer bottom">About</h4>
          <p class="desc-footer w-75">
            Card4games.com selling all Game Cards, Gift Cards and do direct
            Top-up for all games. you can order directly by Card4Games platform
            and complete your order.
          </p>
        </div>
        <div class="col-md-3">
          <h4 class="title-footer bottom">Quick Links</h4>
          <ul class="list-unstyled">
            <li>
              <a
                class="desc-footer link"
                (click)="scrollUp()"
                routerLink="/quick-links/about"
                >About Us</a
              >
            </li>
            <li>
              <a
                class="desc-footer link"
                (click)="scrollUp()"
                routerLink="/quick-links/contacts"
                >Contact Us</a
              >
            </li>

            <li>
              <a
                class="desc-footer link"
                (click)="scrollUp()"
                routerLink="/quick-links/privacy-policy"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a
                class="desc-footer link"
                (click)="scrollUp()"
                routerLink="/quick-links/refund-policy"
                >Refund policy</a
              >
            </li>
            <li>
              <a
                class="desc-footer link"
                (click)="scrollUp()"
                routerLink="/quick-links/terms-condition"
                >terms & condition</a
              >
            </li>
            <li>
              <a
                class="desc-footer link"
                (click)="scrollUp()"
                routerLink="/quick-links/blogs"
                >Blogs</a
              >
            </li>
            <li>
              <a
                class="desc-footer link"
                (click)="scrollUp()"
                routerLink="/quick-links/FAQ"
                >FAQ's</a
              >
            </li>
            <li>
              <a
                class="desc-footer link"
                (click)="scrollUp()"
                routerLink="/quick-links/reseller"
                >become a Reseller</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <h4 class="title-footer bottom">Contact Us</h4>
          <ul class="list-unstyled">
            <li class="desc-footer contact d-flex align-items-center">
              <i class="pi pi-map-marker"></i>
              Uk, London, Shelton street
            </li>
            <li class="desc-footer contact d-flex align-items-center">
              <i class="pi pi-phone"></i>
              +201557759967
            </li>
            <li class="desc-footer contact d-flex align-items-center">
              <i class="pi pi-envelope"></i>
              <!-- {{ email }} -->
              <a style="color: #fff" href="mailto:{{ email }}"> {{ email }} </a>
            </li>
            <li class="desc-footer contact d-flex align-items-center">
              <i class="pi pi-clock"></i>
              7 Days / 24 Hours
            </li>
          </ul>
        </div>
        <p style="text-align: center" class="desc-footer copy-right w-75">
          All rights reserved ©️ 2024 Card4Games.com
        </p>
      </div>
    </div>
  </div>
</section>
